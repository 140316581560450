import React, { useEffect } from 'react'
import Layout from '../components/layout'
import { useTranslation } from 'react-i18next'
import Spacer from '../uielements/Spacer'
import BgImage from '../components/BgImage'
import RentalCategories from '../components/RentalCategories'
import InnerTitle from '../uielements/InnerTitle'
import Wrapper from '../components/Wrapper'

const IndexPage = () => {
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language
    let language = currentLang

    useEffect(() => {
        const lang =
            new URLSearchParams(window.location.search).get('lang') ?? 'el'
        language =
            currentLang !== lang ? i18n.changeLanguage(lang) : currentLang
    }, [])

    return (
        <Layout>
            <BgImage txt={'Rafalift'}>
                <h1>
                    <strong>ΕΝΟΙΚΙΑΣΕΙΣ ΑΝΥΨΩΤΙΚΩΝ & ΜΗΧΑΝΗΜΑΤΩΝ</strong>
                </h1>
                Στην επιχείρηση μας διαθέτουμε μια μεγάλη ποικιλία ανυψωτικών
                μηχανημάτων που χωρίζονται σε ΑΝΥΨΩΤΙΚΕΣ ΠΛΑΤΦΟΡΜΕΣ, ΑΝΥΨΩΤΙΚΑ
                ΚΑΛΑΘΟΦΟΡΑ &amp; ΚΛΑΡΚ. Επίσης έχουμε μια μεγάλη γκάμα από
                ΜΗΧΑΝΗΜΑΤΑ – ΕΡΓΑΛΕΙΑ (π.χ. μπετονιέρες, αναδευτήρες, δονητές,
                μηχανή μαρμάρου, κομπρεσέρ, μηχανή πατωμάτων)
            </BgImage>
            <Spacer top="10" />
            <Wrapper>
                <InnerTitle title="ΚΑΤΗΓΟΡΙΕΣ ΜΗΧΑΝΗΜΑΤΩΝ" />
            </Wrapper>
            <Spacer top="5" />
            <RentalCategories />
            <Spacer top="10" />
        </Layout>
    )
}

export default IndexPage
