import React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Wrapper from '../Wrapper'
import Rental from './rental'

const RentalWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
`

const RentalCategories = () => {
    const { i18n } = useTranslation()

    const allRentalCategories = useStaticQuery(
        graphql`
            query FetchAllRentalCategories {
                allContentfulRentalCategories {
                    nodes {
                        id
                        excerpt {
                            raw
                        }
                        slug
                        title
                        node_locale
                        featuredImage {
                            url
                            title
                            gatsbyImageData(
                                quality: 75
                                jpegProgressive: true
                                sizes: "1920, 960, 480"
                            )
                        }
                    }
                }
            }
        `
    )

    const items = allRentalCategories.allContentfulRentalCategories.nodes

    return (
        <Wrapper>
            <RentalWrapper>
                {items.map(
                    (item) =>
                        i18n.language === item.node_locale && (
                            <Rental
                                key={item.id}
                                title={item.title}
                                slug={item.slug}
                                bgImg={item.featuredImage.url}
                            ></Rental>
                        )
                )}
            </RentalWrapper>
        </Wrapper>
    )
}

export default RentalCategories
