import React from 'react'
import PropTypes, { string } from 'prop-types'
import styled from 'styled-components'
import { theme } from '../../theme'
import Link from 'gatsby-link'
import { screenSizes } from '../../lib/constants'

const RentalWrapperBox = styled.div`
    display: block;
    width: calc(50% - 10px);
    margin: 5px;
    height: 400px;
    overflow: hidden;
    position: relative;

    @media (max-width: ${screenSizes.md}px) {
        width: 100%;
    }
`

const RentalBox = styled.div`
    padding: 10px;
    background-image: url(${(props) => props.bgImg});
    background-position: center;
    background-size: cover;
    position: relative;
    transition: all 0.5s ease-in;
    width: 100%;
    height: 100%;
    display: block;

    &:hover {
        transform: scale(1.1);
    }
`

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`

const RentalTitle = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 2;
    color: ${theme.white};
    font-size: 1.7rem;
    font-weight: bold;
    background: ${theme.red};
    opacity: 0.8;
    padding: 10px;

    &:hover {
        opacity: 1;
    }

    @media (max-width: ${screenSizes.lg}px) {
        font-size: 1.4rem;
    }
`

const Rental = (props) => {
    return (
        <RentalWrapperBox>
            <RentalBox {...props}>
                <Link to={'/rental/' + props.slug}>
                    <Overlay />
                </Link>
            </RentalBox>
            <Link to={'/rental/' + props.slug}>
                <RentalTitle>{props.title}</RentalTitle>
            </Link>
        </RentalWrapperBox>
    )
}

Rental.defaultProps = {
    bgImg: 'https://via.placeholder.com/1920x1080',
    title: 'some title',
    slug: 'some_slug',
}

Rental.propTypes = {
    bgImg: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
}

export default Rental
